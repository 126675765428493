import React, { useState, useEffect } from "react";

export default function Message() {
  const [messages, setMessages] = useState({});
  const [clientMessages, setClientMessages] = useState({});
  const [input, setInput] = useState("");
  const [loggedInUser, setLoggedInUser] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const userEmail = localStorage.getItem("loggedInUser");
    if (userEmail) {
      setLoggedInUser(userEmail);
      fetchUsers();
      addUserToCollection(userEmail);
    } else {
      console.error("No logged in user found");
    }
  }, []);

  useEffect(() => {
    let intervalId;

    if (selectedUser) {
      const fetchMessages = async () => {
        await fetchAdminMessages(loggedInUser, selectedUser);
        await fetchClientMessages(selectedUser);
      };

      fetchMessages(); // Fetch messages immediately on user selection

      intervalId = setInterval(fetchMessages, 2000); // Poll every 2 seconds
    }

    return () => {
      clearInterval(intervalId); // Clear interval on component unmount or user change
    };
  }, [selectedUser]);

  const fetchUsers = async () => {
    try {
      const response = await fetch("https://ai.siw.sg/api/users");
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      if (Array.isArray(data)) {
        setUsers(
          data.map((user) => ({
            email: user._id,
            timestamp: user.timestamp,
            avatar: "user (1).png",
          }))
        );
      } else {
        console.error("Unexpected response format:", data);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const addUserToCollection = async (email) => {
    try {
      await fetch("https://ai.siw.sg/api/users", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });
    } catch (error) {
      console.error("Error adding user:", error);
    }
  };

  const fetchAdminMessages = async (sender, recipient) => {
    try {
      const response = await fetch(
        `https://ai.siw.sg/api/messages?sender=${encodeURIComponent(
          sender
        )}&recipient=${encodeURIComponent(recipient)}`
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      if (Array.isArray(data)) {
        setMessages((prevMessages) => ({
          ...prevMessages,
          [recipient]: data,
        }));
      } else {
        console.error("Unexpected response format:", data);
      }
    } catch (error) {
      console.error("Error fetching admin messages:", error);
    }
  };

  const fetchClientMessages = async (email) => {
    try {
      const response = await fetch(
        `https://ai.siw.sg/api/clientmessages/${email}`
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      if (Array.isArray(data)) {
        setClientMessages((prevClientMessages) => ({
          ...prevClientMessages,
          [email]: data,
        }));
      } else {
        console.error("Unexpected response format:", data);
      }
    } catch (error) {
      console.error("Error fetching client messages:", error);
    }
  };

  const handleSendMessage = async () => {
    if (input.trim() && selectedUser) {
      try {
        const newMessage = {
          senderEmail: loggedInUser,
          recipientEmail: selectedUser,
          text: input,
          timestamp: new Date().toISOString(),
        };
        const response = await fetch("https://ai.siw.sg/api/messages", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newMessage),
        });
        if (response.ok) {
          setMessages((prevMessages) => ({
            ...prevMessages,
            [selectedUser]: [...(prevMessages[selectedUser] || []), newMessage],
          }));
          setInput(""); // Clear the input field
        } else {
          const errorData = await response.json();
          console.error("Error sending message:", errorData);
        }
      } catch (error) {
        console.error("Error sending message:", error);
      }
    }
  };

  // Combine and sort messages by timestamp for the selected user
  const sortedMessages = [
    ...(messages[selectedUser] || []),
    ...(clientMessages[selectedUser] || []),
  ].sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));

  return (
    <div className="dashboard__main">
      <div className="dashboard__content bg-light-4">
        <div className="row pb-50 mb-10 text-center">
          <div className="col-auto">
            <h1 className="text-30 lh-12 fw-700">Messages</h1>
            <div className="mt-10">This is admin dashboard</div>
          </div>
        </div>
  
        <div className="msg-container">
        <div className="msg-sidebar rounded-16 bg-white shadow-4">
  <div className="py-20 px-30 border-bottom-light">
    <h2 className="text-17 lh-1 fw-500">Users</h2>
  </div>
  <div className="py-30 px-30">
    <div className="y-gap-30">
      {users.map((user, i) => (
        <div
          key={i}
          className="user-item cursor-pointer"
          onClick={() => setSelectedUser(user.email)}
        >
          <div className="d-flex items-center">
            <img src={user.avatar} alt="user" className="size-50" />
            <div className="ml-10">
              <div className="user-email lh-11 fw-500 text-dark-1">
                {user.email}
              </div>
              <div className="text-14 lh-11 mt-5">
                {new Date(user.timestamp).toLocaleDateString()}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
</div>

  
          <div className="col-xl-8 rounded-16 bg-white shadow-4">
            <div className="d-flex items-center justify-between py-20 px-30 border-bottom-light">
              <div className="d-flex items-center">
                <img
                  src={selectedUser ? "2.png" : "4.png"}
                  alt="image"
                  className="size-50"
                />
                <div className="ml-10">
                  <div className="lh-11 fw-500 text-dark-1 useremail">
                    {selectedUser || "Select a user"}
                  </div>
                  <div className="text-14 lh-11 mt-5">Active</div>
                </div>
              </div>
            </div>
  
            <div className="chat-container">
              <div className="row y-gap-20">
                {sortedMessages.length === 0 ? (
                  <div>No messages to display.</div>
                ) : (
                  sortedMessages.map((message, index) => (
                    <div
                      key={index}
                      className={`col-xl-7 col-lg-10 ${
                        message.senderEmail === loggedInUser
                          ? "admin-message"
                          : "client-message"
                      }`}
                    >
                      <div className="d-flex items-center">
                        {message.senderEmail === loggedInUser ? (
                          <>
                            <div className="ml-10">
                              <div className="lh-11 fw-500 text-dark-1">
                                {message.senderEmail === loggedInUser
                                  ? "You"
                                  : message.senderEmail}
                              </div>
                              <div className="text-14 lh-11 ml-10">
                                {new Date(message.timestamp).toLocaleTimeString()}
                              </div>
                            </div>
                            <img
                              src="/4.png"
                              alt="image"
                              className="size-50"
                            />
                          </>
                        ) : (
                          <>
                            <img
                              src="/2.png"
                              alt="image"
                              className="size-50"
                            />
                            <div className="ml-10">
                              <div className="lh-11 fw-500 text-dark-1">
                                {message.senderEmail === loggedInUser
                                  ? "You"
                                  : message.senderEmail}
                              </div>
                              <div className="text-14 lh-11 ml-10">
                                {new Date(message.timestamp).toLocaleTimeString()}
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                      <div className="d-inline-block mt-15">
                        <div
                          className={`py-20 px-30 rounded-8 ${
                            message.senderEmail === loggedInUser
                              ? "bg-light-3"
                              : "bg-light-3"
                          }`}
                        >
                          {message.text}
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
  
            <div className="border-top-light py-20 px-30">
              <div className="textarea-container">
                <textarea
                  className="py-5 px-5 text-dark-1"
                  rows="2"
                  placeholder="Type a message"
                  value={input}
                  onChange={(e) => setInput(e.target.value)}
                ></textarea>
                <button className="button" onClick={handleSendMessage}>
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  
}
