
import '../index.css';
import Header from './Header';

const Home = ({username,avatar}) => {
  

  return (
    <div className="home-container -dark-bg-dark-1">
    < Header username={username} avatar={avatar} />
      <main className="main-content">
        <div className="text-content ">
          <h1 className='text-dark-1'>Unlock Your Career Potential with AI-Driven Tools</h1>
          <p className='text-dark-1'>Get personalized career advice and job opportunities with our advanced AI technology.</p>
        </div>
        <div className="image-container">
          <img src="../Job hunt.gif" alt="Career Illustrations" />
        </div>
      </main>
    </div>
  );
};

export default Home;
