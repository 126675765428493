import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Components/Home';
import Login from './Components/Login';
import SignUp from './Components/Signup';
import ResumeWriting from './Components/ResumeWriting';
import Network from './Components/Network';
import CareerPivot from './Components/CareerPivot';
import SalaryNegotiation from './Components/SalaryNegotiation';
import InterviewPreparation from './Components/InterviewPreparation';
import JobMarketAnalysis from './Components/JobMarketAnalysis';
import ProDevelop from './Components/ProDevelop';
import Contact from './Components/Contact';
import ResumeForm from './Components/ResumeForm';
import Profile from './Components/Profile';
import Cv from './Components/Cv';
import UserProfile from './Components/UserProfile';
import PersonalInfo from './Components/PersonalInfo';
import LoginPhn from './Components/LoginPhn';
import Messages from './Components/Messages';
import DshbMessagesPage from './Components/dshb-messages';

function App() {
  const [username, setUsername] = useState(null);
  const [avatar,setAvatar] = useState(null);



  return (
    <Router>
      <Routes>
        <Route path="/home" element={<Home  username={username} avatar={avatar}/>} />
        <Route path="/signup" element={<SignUp setUsername={setUsername} setAvatar={setAvatar} />} />
        <Route path="/" element={<Login setUsername={setUsername} setAvatar={setAvatar} /> } />
        <Route path="/loginphn" element={<LoginPhn setUsername={setUsername} setAvatar={setAvatar} /> } />
        <Route path="/resume-writing" element={<ResumeWriting username={username} avatar={avatar} />} />
        <Route path="/network" element={<Network username={username} avatar={avatar} />} />
        <Route path="/career-pivot" element={<CareerPivot username={username} avatar={avatar} />} />
        <Route path="salary" element={<SalaryNegotiation username={username} avatar={avatar} />} />
        <Route path="/interview" element={<InterviewPreparation username={username} avatar={avatar} />} />
        <Route path="/analysis" element={<JobMarketAnalysis username={username} avatar={avatar} />} />
        <Route path="/prodevelop" element={<ProDevelop username={username} avatar={avatar} />} />
        <Route path="/contact" element={<Contact username={username} avatar={avatar}/>} />
        <Route path="/resumeform" element={<ResumeForm username={username} avatar={avatar}/>} />
        <Route path="/profile/:email" element={<Profile username={username} avatar={avatar}/>} />
        <Route path="/cv" element={<Cv />} />
        <Route path="/userprofile" element={<UserProfile  username={username} avatar={avatar}/>} />
        <Route path="/personalinfo" element={<PersonalInfo  username={username} avatar={avatar}/>} />
        <Route path='/messages' element={<Messages/>}></Route>
        <Route path='/dshb-messages' element={<DshbMessagesPage/>}></Route>
   

      </Routes>
    </Router>
  );
}

export default App;

