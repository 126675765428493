import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
// import { useState } from 'react';
import { authentication } from '../firebaseConfig';
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import "../index.css";



export default function Login({setUsername, setAvatar}) {
 
  const [menuOpen, setMenuOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();
 
  const [loggedInUser, setLoggedInUser] = useState(null);
  const [username,setUserName]=useState("");

  const countryCode = "+91";
  // Phone number state
  const [phoneNumber, setPhoneNumber] = useState(countryCode);
  const [expandForm, setExpandForm] = useState(false);
  const [OTP, setOTP] = useState('');

  useEffect(() => {
    const userEmail = localStorage.getItem("loggedInUser");
    if (userEmail) {
      setLoggedInUser(userEmail);
    }
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/home", { state: { email: loggedInUser } });
    }
  }, [isLoggedIn, navigate, loggedInUser]);

  const generateRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
      size: 'invisible',
      callback: (response) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
      },
      'expired-callback': () => {
        // Response expired. Ask user to solve reCAPTCHA again.
      }
    }, authentication);
  }

  const requestOTP = (e) => {
    e.preventDefault();
    if (phoneNumber.length >= 12) {
      setExpandForm(true);
      generateRecaptcha();
      let appVerifier = window.recaptchaVerifier;
      signInWithPhoneNumber(authentication, phoneNumber, appVerifier)
        .then(confirmationResult => {
          window.confirmationResult = confirmationResult;
        }).catch((error) => {
          // Error; SMS not sent
          console.log(error);
        });
    }
  }

  const verifyOTP = (e) => {
    let otp = e.target.value;
    setOTP(otp);
    if (otp.length === 6) {
      console.log(otp);
      window.confirmationResult.confirm(otp).then((result) => {
        // User signed in successfully.
        const user = result.user;
        console.log('User signed in:', user);
        navigate("/home")
      }).catch((error) => {
        // User couldn't sign in (bad verification code?)
        console.log(error);
      });
    }
  }

  return (
    <div className="login-container">
      {!isLoggedIn ? (
        <form className="login-form" onSubmit={requestOTP}>
          <div className="container">
            <header className="header">
              <div className="logo">
                <img src="../siw-new logo.png" alt="Smart iLab Works" />
              </div>
              <div className="menu-icon" onClick={toggleMenu}>☰</div>
              <nav className={`nav-links ${menuOpen ? "open" : ""}`}>
                <Link to="/Login">Login</Link>
                <Link to="/Signup">Sign Up</Link>
              </nav>
            </header>
            <div className="content">
              <div className="login-box">
                <h2>Login</h2>
                <div className="input-group">
                <label htmlFor="phoneNumberInput" className="form-label">Phone number</label>
          <input type="tel" className="form-control" id="phoneNumberInput" aria-describedby="phoneNumberHelp" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
          <div id="phoneNumberHelp" className="form-text">Please enter your phone number</div>
                </div>
                {expandForm === true ? (
                <div className="input-group">
                   <label htmlFor="otpInput" className="form-label">OTP</label>
            <input type="number" className="form-control" id="otpInput" value={OTP} onChange={verifyOTP} />
            <div id="otpHelp" className="form-text">Please enter the one-time pin sent to your phone</div>
                </div>
                  ) : null}
                 {expandForm === false ? (
          <button style={{padding:"20px"}} type="submit" className="btn btn-primary">Request OTP</button>
          
        ) : null}
        <div id="recaptcha-container"></div>
              </div>
              <div className="illustration">
                <img src="illustration.jpg" alt="Illustration" />
              </div>
            </div>
          </div>
          
        </form>
        
      ) : null}
    </div>
  );
}
