import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Header from './Header';
import { FaLinkedin, FaFacebook, FaTwitter } from 'react-icons/fa';
import Cv from './Cv';
import { saveAs } from 'file-saver';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const Profile = () => {
  const { email } = useParams();
  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [activeTab, setActiveTab] = useState('profile');
  const [resumeResponse, setResumeResponse] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [editedResumeContent, setEditedResumeContent] = useState('');
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const resProfile = await axios.get(`https://ai.siw.sg/getresumeform/${email}`);
        setProfileData(resProfile.data);
      } catch (error) {
        console.error('Error fetching profile data:', error);
      } finally {
        setLoading(false);
      }

      try {
        const resResume = await axios.get(`https://ai.siw.sg/getresumewriting/${email}`);
        setResumeResponse(resResume.data);
        setEditedResumeContent(resResume.data.resumeContent); // Set initial edited content
        setSubmitted(true);
      } catch (error) {
        console.error('Error fetching resume data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [email]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleEditClick = () => {
    setEditMode(true);
  };

  
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  
  const handleDownloadPdf = () => {
    if (resumeResponse && typeof resumeResponse.resumeContent === 'string') {
      const lines = resumeResponse.resumeContent.split('\n');
  
      const leftColumnContent = [];
      const rightColumnContent = [];
  
      let currentColumn = 'left';
  
      lines.forEach(line => {
        if (line.startsWith('Professional Experience:') || line.startsWith('Projects')) {
          currentColumn = 'right'; // Switch to the right column for these headings
          rightColumnContent.push({ text: line, style: 'header' });
        } else if (line.startsWith('Certifications:')) {
          currentColumn = 'left'; // Switch to the left column for certifications
          leftColumnContent.push({ text: line, style: 'header' });
        } else if (line.startsWith('Summary:') || line.startsWith('Technical Skills:') || line.startsWith('Additional Information:')) {
          // These headings should be bold in both columns
          if (currentColumn === 'right') {
            rightColumnContent.push({ text: line, style: 'boldHeader' });
          } else {
            leftColumnContent.push({ text: line, style: 'boldHeader' });
          }
        } else {
          // Add content to the current column
          if (currentColumn === 'right') {
            rightColumnContent.push({ text: line, style: 'normal' });
          } else {
            leftColumnContent.push({ text: line, style: 'normal' });
          }
        }
      });
  
      const docDefinition = {
        content: [
          {
            columns: [
              {
                width: '*',
                stack: leftColumnContent,
                margin: [0, 0, 20, 0] // Add right margin for left column
              },
              {
                width: '*',
                stack: rightColumnContent,
                margin: [20, 0, 0, 0] // Add left margin for right column
              }
            ]
          }
        ],
        styles: {
          header: {
            fontSize: 14,
            bold: true,
            margin: [0, 0, 0, 10],
            lineHeight: 1.5 // Set line height for header
          },
          boldHeader: {
            fontSize: 14,
            bold: true,
            margin: [0, 0, 0, 10],
            lineHeight: 1.5 // Set line height for bold header
          },
          normal: {
            fontSize: 12,
            margin: [0, 0, 0, 5],
            lineHeight: 1.5 // Set line height for normal text
          }
        }
      };
  
      pdfMake.createPdf(docDefinition).download('resume_response.pdf');
    }
  };
  

  const handleSaveClick = async () => {
    try {
      await axios.post(`https://ai.siw.sg/updateresume`, {
        email: email,
        resumeContent: editedResumeContent,
      });
      setResumeResponse((prev) => ({ ...prev, resumeContent: editedResumeContent }));
      setEditMode(false);
      console.log('Edited Successfully');
    } catch (error) {
      console.error('Error updating resume content:', error);
    }
  };

  return (
    <div>
      <Header />
      <div className="container1 -dark-bg-dark-1">
        {loading && <p>Loading...</p>}
        {error && <p>{error}</p>}
        {profileData && (
          <>
            <div className="sidebar1 -dark-bg-dark-1">
              <h2 className="name1 text-dark-1">{profileData.name}</h2>
              <p className="title1">{profileData.jobTitle}</p>
              <div className="social-icons1">
                <div className='icons'>
                  <FaLinkedin />
                </div>
                <div className='icons'>
                  <FaFacebook />
                </div>
                <div className='icons'>
                  <FaTwitter />
                </div>
              </div>
              <hr></hr>
              <div className="contact-info1 ">
                <h3 className="section-title1 text-dark-1">Contact Info</h3>
                <div className="contact-item1">
                  <h3 className="contact-item1 text-dark-1">Location</h3>
                  <div className="experience-item1">
                    <div className="experience-details1 text-dark-1">
                      <p>{profileData.location}</p>
                    </div>
                  </div>
                </div>
                <div className="contact-item1">
                  <h3 className="contact-item1 text-dark-1">Email</h3>
                  <div className="experience-item1">
                    <div className="experience-details1 text-dark-1">
                      <p>{profileData.email}</p>
                    </div>
                  </div>
                </div>
                <div className="contact-item1">
                  <h3 className="contact-item1 text-dark-1">Phone</h3>
                  <div className="experience-item1">
                    <div className="experience-details1 text-dark-1">
                      <p>{profileData.phoneNumber}</p>
                    </div>
                  </div>
                </div>
                <Cv />
              </div>
            </div>

            <div className="main-content1 -dark-bg-dark-1">
              <div className='font-size'>
                <a
                  className={`a1 ${activeTab === 'profile' ? 'active' : ''}`}
                  onClick={() => handleTabClick('profile')}
                >
                  PROFILE
                </a>
                <a
                  className={`a2 ${activeTab === 'resume' ? 'active' : ''}`}
                  onClick={() => handleTabClick('resume')}
                >
                {profileData.name}'s CV
                </a>
                <hr></hr>
              </div>
              {activeTab === 'profile' && (
                <div className='input-1'>
                  <h3 className="section-title1 text-dark-1">About</h3>
                  <p>{resumeResponse.Summary}</p>
                  <h3 className="section-title1 text-dark-1">Skills</h3>
                  <p>{resumeResponse.skills}</p>
                  <div className="experience1">
                    <h3 className="section-title1 text-dark-1">Work Experience</h3>
                    <p>{profileData.experience}</p>
                    <div className="experience-items text-dark-1">
                      <div className="experience-details1 text-dark-1">
                        <h4>{profileData.jobTitle}</h4>
                        <p>{profileData.companyName}</p>
                        <p>{profileData.duration} - {profileData.experience}</p>
                      </div>
                    </div>
                  </div>
                  <div className="experience1">
                    <h3 className="section-title1 text-dark-1">Education</h3>
                    <div className="experience-items">
                      <div className="experience-details1">
                        <p>{profileData.education}</p>
                      </div>
                    </div>
                  </div>
                  <div className="experience1 text-dark-1">
                    <h3 className="section-title1 text-dark-1">Projects</h3>
                    <div className="experience-item1">
                      <div className="experience-details1 text-dark-1">
                        <p>{resumeResponse.Projects}</p>
                      </div>
                    </div>
                  </div>
                  <div className="experience1 text-dark-1">
                    <h3 className="section-title1 text-dark-1">Certifications</h3>
                    <div className="experience-item1 ">
                      <div className="experience-details1 text-dark-1">
                        <p>{profileData.certifications}</p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {activeTab === 'resume' && (
                <div>
                  {editMode ? (
                    <>
                      <textarea className='editText'
                        value={editedResumeContent}
                        onChange={(e) => setEditedResumeContent(e.target.value)}
                        rows="20"
                        cols="80"
                      />
                      <div className='profilebtn'>
                      <button onClick={handleSaveClick}>Save</button>
                      </div>
                    </>
                  ) : (
                    <>
                      {resumeResponse.resumeContent.split('\n').map((line, index) => (
                        <p key={index}>{line}</p>
                      ))}
                      <div className='profilebtn'>
                     
                      <button onClick={handleEditClick}>Edit</button>
                      
                      <button onClick={handleDownloadPdf}>Download as PDF</button>
                      
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Profile;
