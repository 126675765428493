// import Preloader from "@/components/common/Preloader";
// import DashboardOne from "@/components/dashboard/DashboardOne";
import Message from "../dashboard/Messages";
// import Sidebar from "@/components/dashboard/Sidebar";
import Header from "../Header";
import React from "react";
// import MetaComponent from "@/components/common/MetaComponent";

const metadata = {
  title:
    "Alvigor || Role Analyser",
  description:
    "Role Analyser",
};
export default function DshbMessagesPage({ username }) {
  return (
    <div className="barba-container">
      <Header />
     <Message/>
    </div>
  );
}
