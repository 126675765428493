import React, { useState, useEffect } from 'react';
import { TextField, Container, Grid, Box, Button } from '@mui/material';
import { AccountCircle, LocationCity, Home, Business, LocalPostOffice } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import Header from './Header';

const PersonalInfo = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    companyName: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zipCode: ''
  });
  const [loggedInUser, setLoggedInUser] = useState('');

  useEffect(() => {
    const userEmail = localStorage.getItem("loggedInUser");
    if (userEmail) {
      setLoggedInUser(userEmail);
      fetchUserData(userEmail); // Fetch data on mount
    }
  }, []);

  const fetchUserData = async (email) => {
    try {
      const response = await fetch(`https://ai.siw.sg/get-personal-info?email=${email}`);
      if (response.ok) {
        const data = await response.json();
        setFormData(data); // Populate form with fetched data
      } else {
        console.error('Error fetching data');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const dataToSend = {
        ...formData,
        userEmail: loggedInUser,
      };

      const response = await fetch('https://ai.siw.sg/submit-personal-info', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataToSend),
      });

      if (response.ok) {
        console.log('Data submitted successfully');
        navigate('/home');
      } else {
        console.error('Error submitting data');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div>
      <Header />
      <Container className='text-dark-1' maxWidth="sm" style={{ marginTop: "70px" }}>
        <h1 style={{ textAlign: "center" }}>Personal Information</h1>
        <Box component="form" noValidate autoComplete="off" sx={{ mt: 2 }} onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            {/* Render TextFields for form inputs */}
            {/* Repeat for other fields */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="First Name"
                name="firstName"
                value={formData.firstName}  
                onChange={handleChange}     
                InputLabelProps={{
                  style: { color: 'var(--color-dark-1)' },
                }}
                InputProps={{
                  startAdornment: (
                    <AccountCircle className='text-dark-1' sx={{ mr: 1, color: 'var(--color-dark-1)' }} />
                  ),
                  classes: {
                    notchedOutline: 'text-dark-1-border',
                  },
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    color: 'var(--color-dark-1)',
                    '& fieldset': {
                      borderColor: 'var(--color-dark-1)',
                    },
                    '&:hover fieldset': {
                      borderColor: 'var(--color-dark-1)',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'var(--color-dark-1)',
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Last Name"
                name="lastName"
                value={formData.lastName}  // Bind the state to value
                onChange={handleChange}     // Handle change event
                InputLabelProps={{
                  style: { color: 'var(--color-dark-1)' }, // Label text color
                }}
                InputProps={{
                  startAdornment: (
                    <AccountCircle className='text-dark-1' sx={{ mr: 1, color: 'var(--color-dark-1)' }} />
                  ),
                  classes: {
                    notchedOutline: 'text-dark-1-border', // Custom class for border
                  },
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    color: 'var(--color-dark-1)', // Input text color
                    '& fieldset': {
                      borderColor: 'var(--color-dark-1)', // Custom border color
                    },
                    '&:hover fieldset': {
                      borderColor: 'var(--color-dark-1)', // Custom border color on hover
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'var(--color-dark-1)', // Custom border color when focused
                    },
                  },
                }}
              />
            </Grid>
            {/* Repeat the Grid items for other fields, adding value and onChange */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Company Name"
                name="companyName"
                value={formData.companyName}  // Bind the state to value
                onChange={handleChange}       // Handle change event
                InputLabelProps={{
                  style: { color: 'var(--color-dark-1)' }, // Label text color
                }}
                InputProps={{
                  startAdornment: (
                    <Business className='text-dark-1' sx={{ mr: 1, color: 'var(--color-dark-1)' }} />
                  ),
                  classes: {
                    notchedOutline: 'text-dark-1-border', // Custom class for border
                  },
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    color: 'var(--color-dark-1)', // Input text color
                    '& fieldset': {
                      borderColor: 'var(--color-dark-1)', // Custom border color
                    },
                    '&:hover fieldset': {
                      borderColor: 'var(--color-dark-1)', // Custom border color on hover
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'var(--color-dark-1)', // Custom border color when focused
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Address Line 1"
                name="address1"
                value={formData.address1}  // Bind the state to value
                onChange={handleChange}    // Handle change event
                InputLabelProps={{
                  style: { color: 'var(--color-dark-1)' }, // Label text color
                }}
                InputProps={{
                  startAdornment: (
                    <Home className='text-dark-1' sx={{ mr: 1, color: 'var(--color-dark-1)' }} />
                  ),
                  classes: {
                    notchedOutline: 'text-dark-1-border', // Custom class for border
                  },
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    color: 'var(--color-dark-1)', // Input text color
                    '& fieldset': {
                      borderColor: 'var(--color-dark-1)', // Custom border color
                    },
                    '&:hover fieldset': {
                      borderColor: 'var(--color-dark-1)', // Custom border color on hover
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'var(--color-dark-1)', // Custom border color when focused
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Address Line 2"
                name="address2"
                value={formData.address2}  // Bind the state to value
                onChange={handleChange}    // Handle change event
                InputLabelProps={{
                  style: { color: 'var(--color-dark-1)' }, // Label text color
                }}
                InputProps={{
                  startAdornment: (
                    <Home className='text-dark-1' sx={{ mr: 1, color: 'var(--color-dark-1)' }} />
                  ),
                  classes: {
                    notchedOutline: 'text-dark-1-border', // Custom class for border
                  },
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    color: 'var(--color-dark-1)', // Input text color
                    '& fieldset': {
                      borderColor: 'var(--color-dark-1)', // Custom border color
                    },
                    '&:hover fieldset': {
                      borderColor: 'var(--color-dark-1)', // Custom border color on hover
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'var(--color-dark-1)', // Custom border color when focused
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
  <TextField
    fullWidth
    label="City"
    name="city"
    value={formData.city} // Bind to formData
    onChange={handleChange} // Handle input change
    InputLabelProps={{
      style: { color: 'var(--color-dark-1)' }, // Label text color
    }}
    InputProps={{
      startAdornment: (
        <LocationCity className='text-dark-1' sx={{ mr: 1, color: 'var(--color-dark-1)' }} />
      ),
      classes: {
        notchedOutline: 'text-dark-1-border', // Custom class for border
      },
    }}
    sx={{
      '& .MuiOutlinedInput-root': {
        color: 'var(--color-dark-1)', // Input text color
        '& fieldset': {
          borderColor: 'var(--color-dark-1)', // Custom border color
        },
        '&:hover fieldset': {
          borderColor: 'var(--color-dark-1)', // Custom border color on hover
        },
        '&.Mui-focused fieldset': {
          borderColor: 'var(--color-dark-1)', // Custom border color when focused
        },
      },
    }}
  />
</Grid>

<Grid item xs={12}>
  <TextField
    fullWidth
    label="State / Province / Region"
    name="state"
    value={formData.state} // Bind to formData
    onChange={handleChange} // Handle input change
    InputLabelProps={{
      style: { color: 'var(--color-dark-1)' }, // Label text color
    }}
    InputProps={{
      startAdornment: (
        <LocationCity className='text-dark-1' sx={{ mr: 1, color: 'var(--color-dark-1)' }} />
      ),
      classes: {
        notchedOutline: 'text-dark-1-border', // Custom class for border
      },
    }}
    sx={{
      '& .MuiOutlinedInput-root': {
        color: 'var(--color-dark-1)', // Input text color
        '& fieldset': {
          borderColor: 'var(--color-dark-1)', // Custom border color
        },
        '&:hover fieldset': {
          borderColor: 'var(--color-dark-1)', // Custom border color on hover
        },
        '&.Mui-focused fieldset': {
          borderColor: 'var(--color-dark-1)', // Custom border color when focused
        },
      },
    }}
  />
</Grid>

<Grid item xs={12}>
  <TextField
    fullWidth
    label="Zip / Postal Code"
    name="zipCode"
    value={formData.zipCode} // Bind to formData
    onChange={handleChange} // Handle input change
    InputLabelProps={{
      style: { color: 'var(--color-dark-1)' }, // Label text color
    }}
    InputProps={{
      startAdornment: (
        <LocalPostOffice className='text-dark-1' sx={{ mr: 1, color: 'var(--color-dark-1)' }} />
      ),
      classes: {
        notchedOutline: 'text-dark-1-border', // Custom class for border
      },
    }}
    sx={{
      '& .MuiOutlinedInput-root': {
        color: 'var(--color-dark-1)', // Input text color
        '& fieldset': {
          borderColor: 'var(--color-dark-1)', // Custom border color
        },
        '&:hover fieldset': {
          borderColor: 'var(--color-dark-1)', // Custom border color on hover
        },
        '&.Mui-focused fieldset': {
          borderColor: 'var(--color-dark-1)', // Custom border color when focused
        },
      },
    }}
  />
</Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                sx={{ mt: 0 }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
};

export default PersonalInfo;
