import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Input, Button, Checkbox, message, Modal } from "antd";
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import "antd/dist/reset.css"; // Import Ant Design styles

const SignUp = ({ setUsername, setAvatar }) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const firebaseConfig = {
    apiKey: "AIzaSyBfOl3X2jkuQUaqJPxsJ9-4ZG_dYHNa_FQ",
    authDomain: "siw-ai.firebaseapp.com",
    projectId: "siw-ai",
    storageBucket: "siw-ai.appspot.com",
    messagingSenderId: "97322020094",
    appId: "1:97322020094:web:5adfa01afc67bda791c9af",
    measurementId: "G-TC51MW98PR",
  };

  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);

  const handleAuthError = (error) => {
    message.error(error.message);
  };

  useEffect(() => {
    console.log("isLoggedIn:", isLoggedIn);
    console.log("loggedInUser:", loggedInUser);
    if (isLoggedIn && loggedInUser) {
      navigate("/home", { state: { email: loggedInUser } });
    }
  }, [isLoggedIn, loggedInUser, navigate]);

  useEffect(() => {
    const userEmail = localStorage.getItem("loggedInUser");
    const firstName = localStorage.getItem("firstName");
    const lastName = localStorage.getItem("lastName");

    if (userEmail) {
      setLoggedInUser(`${firstName} ${lastName}`);
    }
  }, []);

  async function signInWithGoogle() {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const email = user.email;
      const username = user.displayName;

      localStorage.setItem("loggedInUser", email);

      const response = await fetch("https://ai.siw.sg/GooglelogInUser", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: email }),
      });

      if (!response.ok) {
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.includes("application/json")) {
          const responseData = await response.json();
          throw new Error(responseData.error || "Unexpected server response.");
        } else {
          const errorText = await response.text();
          throw new Error(`Unexpected server response: ${errorText}`);
        }
      }

      console.log("Google sign-in successful");
      console.log(user.displayName);
      localStorage.setItem("username", user.displayName);
      setUsername(user.displayName);
      setAvatar(user.photoURL);

      // Update state to trigger navigation
      setIsLoggedIn(true);
    } catch (error) {
      handleAuthError(error);
      localStorage.removeItem("loggedInUser");
    }
  }

  const handleSubmit = async (values) => {
    if (values.password !== values.confirmPassword) {
      message.error("Passwords must match.");
      return;
    }

    const validationMessage = validatePassword(values.password);
    if (validationMessage !== "valid") {
      message.error(validationMessage);
      return;
    }

    try {
      const response = await fetch("https://ai.siw.sg/signup", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      });

      if (response.ok) {
        message.success("Form submitted successfully");
        localStorage.setItem("firstName", values.firstName);
        localStorage.setItem("lastName", values.lastName);
        navigate("/");
      } else {
        const errorText = await response.text();
        message.error(`Error: ${errorText}`);
      }
    } catch (error) {
      message.error("An error occurred while submitting the form");
    }
  };

  const validatePassword = (password) => {
    if (password.length < 8)
      return "Password must be at least 8 characters long.";
    if (!/[A-Z]/.test(password))
      return "Password must contain at least one uppercase letter.";
    if (!/\d/.test(password))
      return "Password must contain at least one digit.";
    if (!/[!@#$%^&*]/.test(password))
      return "Password must contain at least one special character.";
    return "valid";
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="sign-up-container">
      <header className="header">
        <img
          src="../SiW logo.png"
          alt="Smart iLab Works Logo"
          className="logo"
        />
        <div className="nav-links">
          <a href="/">Login</a>
          <a href="/signup">Sign Up</a>
        </div>
      </header>
      <div className="content">
        <div className="left-section">
          <img src="Sign up.gif" alt="Illustration" className="illustration" />
        </div>
        <div className="right-section">
          <h2>Student Signup</h2>
          <Form form={form} onFinish={handleSubmit} layout="vertical">
            <Form.Item
              name="firstName"
              label="First Name"
              rules={[
                { required: true, message: "Please input your first name!" },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="lastName"
              label="Last Name"
              rules={[
                { required: true, message: "Please input your last name!" },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="email"
              label="E-Mail"
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "Please input a valid email!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="password"
              label="Password"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              name="confirmPassword"
              label="Confirm Password"
              rules={[
                { required: true, message: "Please confirm your password!" },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              name="terms"
              valuePropName="checked"
              rules={[
                {
                  required: true,
                  message: "You must accept the terms and conditions",
                },
              ]}
            >
              <Checkbox>
                I agree to the{" "}
                <a href="#" onClick={showModal}>
                  terms and conditions
                </a>
              </Checkbox>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Sign up
              </Button>
              <Button
                style={{ marginLeft: "10px" }}
                type="default"
                onClick={signInWithGoogle}
              >
                <img
                  src="google (1).png"
                  alt="Google Logo"
                  style={{ width: "13px", height: "13px", marginRight: "8px" }}
                />
                Sign in with Google
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
      <Modal
        title="Terms and Conditions"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="ok" type="primary" onClick={handleOk}>
            OK
          </Button>,
        ]}
      >
        <p>
          1. Introduction
          <br />
          Welcome to Ai.siw. By accessing or using our services, you agree to
          comply with and be bound by the following terms and conditions. Please
          review them carefully before using our platform.
        </p>
        <p>
          2. Acceptance of Terms
          <br />
          By signing up for an account on Ai.siw, you acknowledge that you have
          read, understood, and agree to be bound by these Terms and Conditions,
          as well as our Privacy Policy.
        </p>
        <p>
          3. Eligibility
          <br />
          To use Ai.siw, you must be at least 18 years old. By registering for
          an account, you represent that you meet this eligibility requirement.
        </p>
        <p>
          4. Account Creation
          <br />
          When creating an account, you agree to provide accurate, current, and
          complete information about yourself. You are responsible for
          maintaining the confidentiality of your account credentials.
        </p>
        <p>
          5. Prohibited Activities
          <br />
          You agree not to engage in any activities that violate these Terms and
          Conditions, including but not limited to:<br />
           a. Using the platform for
          any unlawful purpose. <br />
          b. Posting or transmitting harmful, threatening,
          or offensive content. <br />
          c. Attempting to interfere with the platform's
          functionality or security.
        </p>
        <p>
          6. User Content
          <br />
          You retain ownership of the content you submit on Ai.siw. By posting
          content, you grant us a non-exclusive, royalty-free, worldwide license
          to use, reproduce, distribute, and display your content on our
          platform.
        </p>
        <p>
          7. Termination
          <br />
          We reserve the right to terminate or suspend your account at our sole
          discretion if we believe you have violated these Terms and Conditions.
        </p>
        <p>
          8. Limitation of Liability
          <br />
          Ai.siw shall not be liable for any indirect, incidental, or
          consequential damages arising from your use of the platform. Our total
          liability to you for any claim related to the platform shall not
          exceed the amount you paid, if any, for accessing our services.
        </p>
        <p>
          9. Governing Law
          <br />
          These Terms and Conditions shall be governed by and construed in
          accordance with the laws of Singapore. Any disputes arising under or
          in connection with these terms shall be subject to the exclusive
          jurisdiction of the courts in Singapore.
        </p>
        <p>
          10. Changes to Terms
          <br />
          We may update these Terms and Conditions from time to time. It is your
          responsibility to review them periodically for any changes. Your
          continued use of the platform after the changes will signify your
          acceptance of the updated terms.
        </p>
        <p>
          11. Disclaimer of Warranties
          <br />
          Ai.siw provides its services "as is" and "as available" without any warranties, express or implied. We do not guarantee that our services will be error-free, secure, or uninterrupted, and we disclaim all warranties, including those of merchantability and fitness for a particular purpose.
        </p>
        <p>
          12. Contact Us

          <br />
          If you have any questions or concerns about these Terms and Conditions, please contact us at siw.sgp@gmail.com
        </p>
      </Modal>
    </div>
  );
};

export default SignUp;
