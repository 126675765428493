import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Input, Button, message } from 'antd';
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import 'antd/dist/reset.css'; // Import Ant Design styles
import "../index.css"; // Ensure your CSS does not override Ant Design styles

const firebaseConfig = {
  apiKey: "AIzaSyBfOl3X2jkuQUaqJPxsJ9-4ZG_dYHNa_FQ",
  authDomain: "siw-ai.firebaseapp.com",
  projectId: "siw-ai",
  storageBucket: "siw-ai.appspot.com",
  messagingSenderId: "97322020094",
  appId: "1:97322020094:web:5adfa01afc67bda791c9af",
  measurementId: "G-TC51MW98PR",
};
const adminCredentials = {
  email: "admin@example.com",
  password: "Admin@123",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export default function Login({ setUsername, setAvatar }) {
  const [form] = Form.useForm();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();
  const [loggedInUser, setLoggedInUser] = useState(null);

  useEffect(() => {
    const userEmail = localStorage.getItem("loggedInUser");
    const firstName = localStorage.getItem("firstName");
    const lastName = localStorage.getItem("lastName");

    if (userEmail) {
      setLoggedInUser(`${firstName} ${lastName}`);
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/home", { state: { email: loggedInUser } });
    }
  }, [isLoggedIn, navigate, loggedInUser]);

  async function signInWithGoogle() {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const email = user.email;
      const username = user.displayName;
  
      localStorage.setItem("loggedInUser", email);
  
      const response = await fetch("https://ai.siw.sg/GooglelogInUser", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: email }),
      });
  
      if (!response.ok) {
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.includes("application/json")) {
          const responseData = await response.json();
          throw new Error(responseData.error || "Unexpected server response.");
        } else {
          const errorText = await response.text();
          throw new Error(`Unexpected server response: ${errorText}`);
        }
      }
  
      console.log("Google sign-in successful");
      console.log(user.displayName);
      localStorage.setItem("username", user.displayName);
      setUsername(user.displayName);
      setAvatar(user.photoURL);
      
      // Update state to trigger navigation
      setIsLoggedIn(true);
    } catch (error) {
      handleAuthError(error);
      localStorage.removeItem("loggedInUser");
    }
  }

  async function handleSubmit(values) {
    const { email, password } = values;
    if (email === adminCredentials.email && password === adminCredentials.password) {
      localStorage.setItem("loggedInUser", email);
      setUsername("Admin");
      navigate("/dshb-messages");
      return;
    }

    try {
      const response = await fetch("https://ai.siw.sg/login", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(values),
      });

      if (!response.ok) {
        const contentType = response.headers.get("content-type");
        const errorText = contentType && contentType.includes("application/json")
          ? await response.json().then(data => data.error || "Unexpected server response.")
          : await response.text();
        throw new Error(errorText);
      }

      const data = await response.json();
      localStorage.setItem("loggedInUser", data.email);
      localStorage.setItem("firstName", data.firstName);
      localStorage.setItem("lastName", data.lastName);

      setIsLoggedIn(true);
    } catch (error) {
      handleAuthError(error);
    }
  }

  function handleAuthError(error) {
    message.error(error.message);
  }

  return (
    <div className="login-container">
      <header className="header">
        <img src="../SiW logo (4) (1).png" alt="Smart iLab Works Logo" className="logo" />
        <div className="nav-links">
          <Link to="/">Login</Link>
          <Link to="/signup">Sign Up</Link>
        </div>
      </header>
      {!isLoggedIn ? (
        <div className="content">
          <div className="left-section">
            <img src="Sign up.gif" alt="Illustration" className="illustration" />
          </div>
          <div className="right-login-section">
            <h2 style={{color:"white"}}>Login</h2>
            <Form form={form} onFinish={handleSubmit} layout="vertical">
              <Form.Item name="email" label="E-Mail" rules={[{ required: true, type: 'email', message: 'Please input a valid email!' }]}>
                <Input />
              </Form.Item>
              <Form.Item name="password" label="Password" rules={[{ required: true, message: 'Please input your password!' }]}>
                <Input.Password />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">Login</Button>
                <Button style={{ marginLeft: '10px' }} type="default" onClick={signInWithGoogle}>
                <img 
      src="google (1).png" 
      alt="Google Logo" 
      style={{ width: '13px', height: '13px', marginRight: '8px' }} 
    />
                  Sign in with Google</Button>
              </Form.Item>
              <Form.Item>
                <Link to="/signup">Don't have an account? Sign up</Link>
              </Form.Item>
            </Form>
          </div>
        </div>
      ) : (
        <div className="welcome-message">
          <h2>Welcome, {loggedInUser}!</h2>
        </div>
      )}
    </div>
  );
}
