import React, { useState, useEffect } from "react";
import "../index.css";
import UserProfile from "./UserProfile";
import Messages from "./Messages";
import "@fortawesome/fontawesome-free/css/all.min.css";

const Header = ({ avatar }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [username, setUsername] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [messageOpen, setMessageOpen] = useState(false);

  useEffect(() => {
    const storedUsername = localStorage.getItem("username");
    const storedFirstName = localStorage.getItem("firstName");
    const storedLastName = localStorage.getItem("lastName");
    const isDarkMode = localStorage.getItem("darkMode") === "enabled";

    if (storedUsername) {
      setUsername(storedUsername);
    }
    if (storedFirstName) {
      setFirstName(storedFirstName);
    }
    if (storedLastName) {
      setLastName(storedLastName);
    }
    if (isDarkMode) {
      document.documentElement.classList.add("-dark-mode");
    }
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleDarkMode = () => {
    const htmlElement = document.documentElement;
    const isDarkModeEnabled = htmlElement.classList.toggle("-dark-mode");
    localStorage.setItem("darkMode", isDarkModeEnabled ? "enabled" : "disabled");
  };

  return (
    <header className="header">
      <div className="logo">
        <img src="../SiW logo (4) (1).png" alt="Smart iLab Works" />
      </div>
      <div className="menu-icon" onClick={toggleMenu}>
        ☰
      </div>
      <nav className={`nav-linkss ${menuOpen ? "open" : ""}`}>
        <a  href="/home">Home</a>
        <div className="dropdown">
          <a className="dropbtn">Job Seeking Tools</a>
          <div className="dropdown-content -dark-bg-dark-1">
            <a href="/resumeform">Resume Writing</a>
            <a href="/network">Network</a>
            <a href="/career-pivot">Career Pivot</a>
            <a href="/salary">Salary Negotiation</a>
            <a href="/interview">Interview Preparation</a>
            <a href="/analysis">Job Market Analysis</a>
            <a href="/prodevelop">Professional Development Planning</a>
          </div>
        </div>
        <a href="/contact">Contact</a>
        <div>
          <UserProfile
            username={username}
            firstName={firstName}
            lastName={lastName}
            avatar={avatar}
          />
        </div>
        <div className="relative" onClick={() => setMessageOpen(true)}>
          <a
            href="#"
            className="d-flex items-center text-light-1 justify-center size-50 rounded-16 "
            data-el-toggle=".js-msg-toggle"
          >
            <i className="fas fa-envelope" style={{ color: "white" }}></i>
          </a>
        </div>
        <Messages setMessageOpen={setMessageOpen} messageOpen={messageOpen} />
        <button
          onClick={handleDarkMode}
          className="js-darkmode-toggle text-light-1 d-flex items-center justify-center size-50 rounded-16 "
        >
          <i style={{ color: "white" }} className="fas fa-moon"></i>
        </button>
      </nav>
    </header>
  );
};

export default Header;
