import React, { useState, useEffect } from "react";
import { FaPaperPlane } from 'react-icons/fa';
import '@fortawesome/fontawesome-free/css/all.min.css';

export default function Messages({ setMessageOpen, messageOpen }) {
  const [clientMessages, setClientMessages] = useState([]);
  const [adminMessages, setAdminMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [loggedInUser, setLoggedInUser] = useState("");

  useEffect(() => {
    const userEmail = localStorage.getItem("loggedInUser");
    if (userEmail) {
      setLoggedInUser(userEmail);
      fetchMessages(userEmail);
      fetchAdminMessages(userEmail);
      addUserToCollection(userEmail);

      // Set up polling
      const intervalId = setInterval(() => {
        fetchMessages(userEmail);
        fetchAdminMessages(userEmail);
      }, 2000); // Poll every 2 seconds

      // Clean up interval on component unmount
      return () => clearInterval(intervalId);
    }
  }, [loggedInUser]);

  const addUserToCollection = async (email) => {
    try {
      const response = await fetch("https://ai.siw.sg/api/users", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      await response.json();
    } catch (error) {
      console.error('Error adding user to collection:', error);
    }
  };

  const fetchAdminMessages = async (email) => {
    try {
      const response = await fetch(`https://ai.siw.sg/api/messages?sender=admin@example.com&recipient=${email}`);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      const adminMsgsWithFlag = Array.isArray(data)
        ? data.map(msg => ({ ...msg, fromAdmin: true }))
        : [];
      setAdminMessages(adminMsgsWithFlag);
    } catch (error) {
      console.error('Error fetching admin messages:', error);
    }
  };

  const fetchMessages = async (email) => {
    try {
      const response = await fetch(`https://ai.siw.sg/api/clientmessages/${email}`);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      const clientMsgsWithFlag = Array.isArray(data)
        ? data.map(msg => ({ ...msg, fromAdmin: false }))
        : [];
      setClientMessages(clientMsgsWithFlag);
    } catch (error) {
      console.error('Error fetching client messages:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newMessage.trim()) {
      const message = {
        text: newMessage,
        timestamp: new Date().toISOString(),
        fromAdmin: false, // client message
      };
  
      try {
        const response = await fetch('https://ai.siw.sg/api/clientmessages', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email: loggedInUser, message }),
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
  
        const result = await response.json();
        if (result.success) {
          setClientMessages((prevMessages) => [...prevMessages, message]);
          setNewMessage(""); // Clear input field
        } else {
          console.error('Error from server:', result.error);
        }
      } catch (error) {
        console.error('Error sending message:', error);
      }
    }
  };
  
  return (
    <aside className={`chat-ui-container ${messageOpen ? "visible" : ""}`}>
      <div className="chat-header">
        <h2 style={{color:"white",fontSize:"15px"}}>Support Chat</h2>
        <button onClick={() => setMessageOpen(false)} className="close-button">
  <i className="fas fa-times"></i>
</button>

      </div>
      <div className="chat-body">
        <div className="chat-messages">
          {adminMessages.concat(clientMessages).sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp)).map((msg, index) => (
            <div
              key={index}
              className={`message-item ${msg.fromAdmin ? "from-admin" : "from-client"}`}
            >
              <div className="message-text">{msg.text}</div>
              <div className="message-time">{new Date(msg.timestamp).toLocaleTimeString()}</div>
            </div>
          ))}
        </div>
        <div className="chat-footer">
          <form onSubmit={handleSubmit} className="message-form">
            <input
              type="text"
              placeholder="Type your message..."
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              className="message-input"
            />
            <button type="submit" className="send-button">
              <FaPaperPlane size={20} />
            </button>
          </form>
        </div>
      </div>
    </aside>
  );
}
