import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBfOl3X2jkuQUaqJPxsJ9-4ZG_dYHNa_FQ",
  authDomain: "siw-ai.firebaseapp.com",
  projectId: "siw-ai",
  storageBucket: "siw-ai.appspot.com",
  messagingSenderId: "97322020094",
  appId: "1:97322020094:web:5adfa01afc67bda791c9af",
  measurementId: "G-TC51MW98PR"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const authentication = getAuth(app);

