import React, { useState, useEffect } from 'react';
import '../index.css';

const UserProfile = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [username, setUsername] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [avatar, setAvatar] = useState(null); // Optional if you have avatar functionality

  useEffect(() => {
    // Retrieve data from localStorage
    const storedUsername = localStorage.getItem('username');
    const storedFirstName = localStorage.getItem('firstName');
    const storedLastName = localStorage.getItem('lastName');

    console.log('Retrieved username:', storedUsername);
    console.log('Retrieved firstName:', storedFirstName);
    console.log('Retrieved lastName:', storedLastName);

    setUsername(storedUsername);
    setFirstName(storedFirstName);
    setLastName(storedLastName);
  }, []);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem('username');
    localStorage.removeItem('firstName');
    localStorage.removeItem('lastName');
    localStorage.removeItem('loggedInUser')
    window.location.href = '/'; // Redirect to the homepage or login page
  };

  return (
    <div className="profile-dropdown">
      <div>
        <button onClick={toggleDropdown} className="profile-button">
          {/* Uncomment if you want to display the avatar */}
          {/* <img src={avatar} className="profile-avatar" alt="Profile Avatar" /> */}
          <span style={{ color: 'white' }}>
            {username
              ? username
              : firstName && lastName
              ? `${firstName} ${lastName}`
              : 'Guest'}
          </span>
        </button>
        {dropdownOpen && (
          <div className="dropdown-menu -dark-bg-dark-1 text-dark-1">
            <p className='text-dark-1'>Hi, {username || (firstName && lastName ? `${firstName} ${lastName}` : 'Guest')}!</p>
            <ul className='text-dark-1'>
              <li className='text-dark-1'><a href="/personalinfo">Profile</a></li>
              <li><a onClick={handleLogout} style={{ cursor: 'pointer' }}>Log Out</a></li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserProfile;
