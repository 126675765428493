import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Header from './Header';

function ResumeForm() {
  const navigate = useNavigate();
  const [loggedInUser, setLoggedInUser] = useState(null);
  
  const [formData, setFormData] = useState({
    name: '',
    jobTitle: '',
    location: '',
    email: '',
    phoneNumber: '',
    skills: '',
    experience: '',
    companyName: '',
    duration: '',
    education: '',
    certifications: '',
    resume: '',
  });

  useEffect(() => {
    const userEmail = localStorage.getItem("loggedInUser");
    if (userEmail) {
      setLoggedInUser(userEmail);
      const fetchData = async () => {
        try {
          const response = await axios.get(`https://ai.siw.sg/resumeform/${userEmail}`);
          setFormData(response.data);
        } catch (error) {
          console.error('Error fetching resume data:', error.message);
        }
      };
      
      fetchData();
    } else {
      console.error('No user email found in local storage.');
    }
  }, []);
  

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: id === 'name' ? value.toUpperCase() : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const dataToSubmit = { ...formData, email: loggedInUser };
      console.log('Submitting data:', dataToSubmit);
      await axios.post('https://ai.siw.sg/resumeform', dataToSubmit);
      console.log('Navigating to:', `/profile/${loggedInUser}`);
      navigate(`/profile/${loggedInUser}`);
    } catch (error) {
      console.error('Error submitting resume data:', error.message);
    }
  };
  

  return (
    <div>
      <Header />
      <div className="form-container -dark-bg-dark-1">
        <h1>Resume Form</h1>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input type="text" id="name" value={formData.name} onChange={handleChange} placeholder="Enter your name" required />
          </div>

          <div className="form-group">
            <label htmlFor="jobTitle">Job Title</label>
            <input type="text" id="jobTitle" value={formData.jobTitle} onChange={handleChange} placeholder="Enter your job title" required />
          </div>

          <div className="form-group">
            <label htmlFor="location">Location</label>
            <input type="text" id="location" value={formData.location} onChange={handleChange} placeholder="Enter your location" required />
          </div>

          <div className="form-group">
            <label htmlFor="email">E-mail</label>
            <input type="email" id="email" value={loggedInUser || formData.email} onChange={handleChange} placeholder="Enter your e-mail" required readOnly />
          </div>

          <div className="form-group">
            <label htmlFor="phoneNumber">Phone Number</label>
            <input type="text" id="phoneNumber" value={formData.phoneNumber} onChange={handleChange} placeholder="Enter your phone number" required />
          </div>

          <div className="form-group">
            <label htmlFor="skills">Skills</label>
            <input type="text" id="skills" value={formData.skills} onChange={handleChange} placeholder="Enter your skills" required />
          </div>

          <div className="form-group">
            <label htmlFor="experience">Experience</label>
            <input type="text" id="experience" value={formData.experience} onChange={handleChange} placeholder="Describe your experience" />
          </div>

          <div className="form-group">
            <label htmlFor="companyName">Company Name</label>
            <input type="text" id="companyName" value={formData.companyName} onChange={handleChange} placeholder="Enter your company name" />
          </div>

          <div className="form-group">
            <label htmlFor="duration">Duration</label>
            <input type="text" id="duration" value={formData.duration} onChange={handleChange} placeholder="Enter the duration (e.g., Jan 18 - Dec 20)" />
          </div>

          <div className="form-group">
            <label htmlFor="education">Educational Background</label>
            <textarea id="education" value={formData.education} onChange={handleChange} placeholder="Describe your educational background" required></textarea>
          </div>

          <div className="form-group">
            <label htmlFor="certifications">Certifications</label>
            <textarea id="certifications" value={formData.certifications} onChange={handleChange} placeholder="Enter your certifications"></textarea>
          </div>

          <div className='formSubmit'>
            <button type="submit">Submit</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ResumeForm;
