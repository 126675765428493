import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { saveAs } from 'file-saver';
import { message } from 'antd';
import Header from './Header';

export default function Network() {
  const [prompt, setPrompt] = useState('');
  const [response, setResponse] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState('');

  useEffect(() => {
    const userEmail = localStorage.getItem("loggedInUser");
    if (userEmail) {
      setLoggedInUser(userEmail);
      // Fetch user history and display the latest response
      fetchUserHistory(userEmail);
    }
  }, []);

  const fetchUserHistory = async (userEmail) => {
    try {
      const res = await axios.get(`https://ai.siw.sg/user-history/${userEmail}`);
      if (res.data.history && res.data.history.length > 0) {
        // Set the latest response from the history
        const latestResponse = res.data.history[res.data.history.length - 1].response;
        setResponse(latestResponse);
      }
    } catch (error) {
      console.error('Error fetching user history:', error);
    }
  };

  const handleChange = (e) => {
    setPrompt(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post('https://ai.siw.sg/Network', { 
        prompt,
        userEmail: loggedInUser
      });
      setResponse(res.data.response);
      console.log('Prompt:', prompt);
      console.log('Response:', res.data.response);
      setSubmitted(true);
    } catch (error) {
      if (error.response && error.response.status === 403) {
        message.error('Your usage limit has been reached. You are restricted from making further requests.');
      } else {
        console.error('Error:', error.message);
      }
    }
  };

  const handleNew = () => {
    setPrompt('');
    setResponse('');
    setSubmitted(false);
  };

  const handleDownload = () => {
    if (response) {
      const blob = new Blob([response], { type: 'text/plain;charset=utf-8' });
      saveAs(blob, 'transition_response.txt');
    }
  };

  return (
    <div>
      <Header />
      <div className="transition-app">
        <h1 className='transition-h1 text-dark-1'>Network</h1>
        <div className="transition-content-container">
          <form className='transition-form' onSubmit={handleSubmit}>
            <div className="transition-container">
              <div className="input-container">
                <input
                  type='text'
                  id='Transition_box'
                  value={prompt}
                  onChange={handleChange}
                  placeholder="Enter the industry"
                />
                <div className='resume-btn'>
                  <button className='role-button' type="button" onClick={handleNew}>New</button>
                  <button className='transition-submit' type="submit">Submit</button>
                </div>
              </div>
            </div>
          </form>
          {response && (
            <div className="transition-response-container text-dark-1">
              <ul>
                {response.split('\n').map((question, index) => (
                  <li key={index}>{question}</li>
                ))}
              </ul>
              <button id="Download_response" onClick={handleDownload}>Download Response</button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
