import React, { useState , useEffect } from 'react';
import axios from 'axios';
import { saveAs } from 'file-saver';
import { useNavigate } from 'react-router-dom';
import Header from './Header';
import { message } from 'antd';

export default function SalaryNegotiation({}) {
  const navigate = useNavigate();
  const [previousRole, setPreviousRole] = useState('');
  const [previousIndustry, setPreviousIndustry] = useState('');
  const [prompt, setPrompt] = useState('');
  const [response, setResponse] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState('');

  useEffect(() => {
    const userEmail = localStorage.getItem("loggedInUser");
    if (userEmail) {
      setLoggedInUser(userEmail);
      fetchLatestResponse(userEmail);
    }
  }, []);
 
  const handleNew = () => {
    setPrompt('');
    setResponse('');
    setSubmitted(false);
  };

  
  const handlePreviousRoleChange = (event) => {
    setPreviousRole(event.target.value);
  };

  const handlePreviousIndustryChange = (event) => {
    setPreviousIndustry(event.target.value);
  };

  // const handleChange = (e) => {
  //   setPrompt(e.target.value);
  // };
  const fetchLatestResponse = async (userEmail) => {
    try {
      const res = await axios.get(`https://ai.siw.sg/getLatestSalaryResponse`, {
        params: { userEmail } // Pass userEmail as query parameter
      });
      
      if (res.data.history && res.data.history.length > 0) {
        // Set the latest response from the history
        const latestResponse = res.data.history[res.data.history.length - 1].response;
        setResponse(latestResponse);
      }
    } catch (error) {
      console.error('Error fetching user history:', error);
    }
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post('https://ai.siw.sg/SalaryNegotiation', {
        previousRole,
        previousIndustry,
        prompt,
        userEmail: loggedInUser
      });
      setResponse(res.data.response);
      console.log('Prompt:', prompt);
      console.log('PriviousRole:', previousRole);
      console.log('previousIndustry:', previousIndustry);
      console.log('Response:', res.data.response);
      console.log('Form submitted on:', new Date().toLocaleString());
      console.log('session id:');
      setSubmitted(true);
    } catch (error) {
      if (error.response && error.response.status === 403) {
        // Show 403 error as an alert
        message.error('Your usage limit has been reached. You are restricted from making further requests.');
      } else {
        console.error('Error:', error.message);
      }
    }
    // onComplete(true)
  };
  

  const handleDownload = () => {
    if (response) {
      // Convert response to a Blob
      const blob = new Blob([response], { type: 'text/plain;charset=utf-8' });
      // Save the Blob as a file
      saveAs(blob, 'resume_response.txt');
    }
    };
    
    const [menuOpen, setMenuOpen] = useState(false);
    
      const toggleMenu = () => {
        setMenuOpen(!menuOpen);
      };

  return (
    <div>
      < Header />
    <div className="transition-app">
      <h1 className='transition-h1 text-dark-1'>Salary Negotiation</h1>
      <div className="transition-content-container">
        {/* <div className="resume-role-container"> */}
          <form className='transition-form' onSubmit={handleSubmit}>
            <div className="transition-container"> 
              <div className="input-container"> 
                <input
                type="text"
                id="Transition_box"
                value={previousRole}
                onChange={handlePreviousRoleChange}
                placeholder="Enter your job title..."
                /><br></br>
               <input
                type="text"
                id="Transition_box"
                value={previousIndustry}
                onChange={handlePreviousIndustryChange}
                placeholder="Enter the company..."
                />
                {/* <textarea 
                id='Resume_box'
                value={prompt}
                onChange={handleChange}
                placeholder="Enter job description..."
                /> */}
                <div style={{display:'flex', justifyContent:'space-between',margin:'30px 0 0 80px'}} className='resume-button'>
                  <button className='role-button' type="button" onClick={handleNew}>New</button>
                  <button className='transition-submit' type="submit">Submit</button>
                </div>
              </div>
            </div>   
          </form>
      {/* </div> */}
      {response && (
          <div className="transition-response-container text-dark-1">
            <ul>
              {response.split('\n').map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
            <div className="resume-button-container">
              <button id="Download_response" onClick={handleDownload}>Download Response</button>
            </div>
          </div>
        )}
      </div>
    </div>
  </div>
  );
}

