import { Link } from 'react-router-dom';
import '../index.css';
import React, { useState } from 'react';
import Header from './Header';

export default function Contact({}) {
  const [menuOpen, setMenuOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    if (menuOpen) {
      setDropdownOpen(false); // Close dropdown when main menu is closed
    }
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <div className="home-container -dark-bg-dark-1">
      < Header />
      <div className="contact-container -dark-bg-dark-1">
        <div className="contact-card ">
          <div className="contact-icon">
            <img src="../call.png" alt="Call Icon" />
          </div>
          <div className="contact-details">
            <h3>Call Us</h3>
            <p>+65 80866050</p>
          </div>
        </div>
        <div className="contact-card ">
          <div className="contact-icon text-dark-1">
            <img src="../location.png" alt="Location Icon" />
          </div>
          <div className="contact-details text-dark-1">
            <h3>Our Location</h3>
            <p>245, Yishan ave9, Near to A'POSE BIZHUB Singapore</p>
          </div>
        </div>
        <div className="contact-card ">
          <div className="contact-icon">
            <img src= "../mail.png" alt="Mail Icon" />
          </div>
          <div className="contact-details">
            <h3>Email Us</h3>
            <p>admin@siw.sg</p>
          </div>
        </div>
      </div>
    </div>
  );
}
