import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { saveAs } from 'file-saver';

function Cv() {
  const [prompt, setPrompt] = useState('');
  const [response, setResponse] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState(null);

  useEffect(() => {
    const userEmail = localStorage.getItem("loggedInUser");
    if (userEmail) {
      setLoggedInUser(userEmail);
    }
  }, []);

  const handleChange = (e) => {
    setPrompt(e.target.value);
  };

  const handleRefresh = () => {
    window.location.reload();
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const userEmail = localStorage.getItem("loggedInUser");
      const data = { prompt, userEmail };
      console.log('Submitting data:', data); // Log submitted data
      const res = await axios.post('https://ai.siw.sg/ResumeWriting', data);
      console.log('Response from server:', res.data);  // Log response from server
      setResponse(res.data.response);
      setSubmitted(true);
      console.log('State response:', res.data.response);  // Log state response
      
      // Reset form state
      setPrompt('');
    } catch (error) {
      console.error('Error:', error.message);
    }
  };
  
  const handleNew = () => {
    setPrompt(''); // Reset the prompt field
    setResponse('');
    setSubmitted(false);
  };

  const handleDownload = () => {
    if (response) {
      const blob = new Blob([response], { type: 'text/plain;charset=utf-8' });
      saveAs(blob, 'resume_response.txt');
    }
  };

  // const handleRefresh = () => {
  //   window.location.reload();
  // }

  return (
    <div>
      <div className="transition-app2">
        <div className="transition-content-container2">
          <form className='transition-form2' onSubmit={handleSubmit}>
            <div className="transition-container2">
              <div className="input-container2">
                <input
                  type='text'
                  id='Transition_box2'
                  value={prompt}
                  onChange={handleChange}
                  placeholder="Enter your job title"
                />
                <div className='resume-btn2'>
                  <button className='role-button2' type="button" onClick={handleNew}>New</button>
                  <button className='transition-submit2' type="submit">Submit</button>
                </div>
                  {/* <div>
                  <button className='refresh-button' type="button" onClick={handleRefresh}>Refresh</button>
                  </div> */}
                  <div className='refresh2'>
                    <img  src="../Refresh.png" onClick={handleRefresh} />
                    {/* <p>Refresh</p> */}
                  </div>
              </div>
            </div>
          </form>
          {/* {submitted && response && (
            <div className="transition-response-container2">
              <div>
                {response.split('\n').map((line, index) => (
                  <p key={index}>{line}</p>
                ))}
              </div>
              <button id="Download_response2" onClick={handleDownload}>Download Response</button>
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
}

export default Cv;
